<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <submenu-config :menu="category"></submenu-config>

    <div class="submenu-content">
      <div v-if="error" class="alert alert-danger">{{ error }}</div>

      <!-- show page content -->
      <div>
        <!-- banner image -->
        <!-- <div id="banner" class="mb-3" :style="'background-image: url(' + banner + ');'"></div> -->
        <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img :src="banner" class="d-block w-100">
            </div>
          </div>
        </div>

        <!-- page title -->
        <h2 class="mt-5 mb-5">{{category}} Configuration</h2>

        <div v-if="item" class="row row-cols-1 row-cols-md-2 g-4 mb-5">
            <div v-for="data in item" :key="data">
                <div class="col">
                    <div class="card h-100 w-100">
                    <img :src="data.image"
                    height="300"
                    width="335"
                    class="rounded mx-auto d-block">
                    <div class="card-body">
                        <h5 class="card-title">{{data.title}}</h5>
                        <hr>
                        <div class="card-text">
                            {{data.spec}}
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="d-grid gap-2">
                            <center><router-link class="btn btn-success buy_button" :to="'/config/details/?itmno=' + data.itmno">Customize/Buy</router-link></center>
                        </div>
                    </div> 
                    </div>
                </div>
            </div>

        </div>
        

        <!-- <div class="row row-cols-1 row-cols-md-2 g-4">
            <div class="col">
                <div class="card h-100 w-100">
                <img src="@/assets/images/server/SuperServer_4029GP-TRT.png"
                height="300"
                width="335"
                class="rounded mx-auto d-block" alt="...">
                <div class="card-body">
                    <h5 class="card-title">SuperServer 4029GP-TRT</h5>
                    <hr>
                    <div class="card-text">
                        <ul>
                            <li>CofDual 2nd Gen Intel® Xeon® Scalable processors</li>
                            <li>Up to 6TB 3DS ECC DDR4-2933MHz RDIMM/LRDIMM, Supports Intel® Optane™ DCPMM</li>
                            <li>Up to 24 Hot-swap 2.5" drive bays</li>
                            <li>8 PCI-E 3.0 x16 slots support up to 8 double width GPU</li>
                        </ul>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-grid gap-2">
                        <a class="btn btn-success" href="/server/config/details">Customize/Buy</a>
                    </div>
                </div>
                </div>
            </div>
            <div class="col">
                <div class="card h-100 w-100">
                <img src="@/assets/images/server/Thunder_HX_FT48T-B7105.png" 
                height="300"
                width="335"  
                class="rounded mx-auto d-block" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Thunder HX FT48T-B7105</h5>
                    <hr>
                    <div class="card-text">
                        <ul>
                            <li>Dual-Socket Intel Xeon® Scalable Processors</li>
                            <li>Up to 1.5TB DDR4-2667 DDR4 DIMM</li>
                            <li>8 Hot-Swap 3.5" SATA 6G drive bays</li>
                            <li>6 PCIe x16 slots, supporting up to 5 GPU cards</li>
                        </ul>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-grid gap-2">
                        <a class="btn btn-success" href="/server/config/details/?baseid=1">Customize/Buy</a>
                    </div>
                </div>
                </div>
            </div>
        </div> -->


      </div>
      <!-- end of page content -->
    </div>
  </div>
</template>

<script>
import SubmenuConfig from "../../components/nav/SubmenuConfig.vue";
import BreadcrumbPage from "../../components/BreadcrumbPage.vue";
import { ref, onMounted } from "vue";
import { getAPI } from "../../utils/axios-api";
import { capitalize } from '../../utils/common';

export default {
  name: "Config",
  components: { SubmenuConfig, BreadcrumbPage },
  props: ['category'],
  setup(props) {
    const error = ref("");
    const item = ref([]);
    const result = ref();
    const banner = ref(null)
    const category = ref()

    //key is frontend name, value is name in database
    let category_mapping = {"server": "Server",
                        "desktop": "Workstation"}
    let cate_in_db = category_mapping[props.category]
    category.value = capitalize(props.category)


    //breadcrumb
    const breadcrumb_links = ref([]);
    breadcrumb_links.value.push({ text: "Configuration", link: "" });
    breadcrumb_links.value.push({
      text: category.value  + " Configuration",
      link: "Config",
    });
    
    
    //get banner
    getAPI
      .get("/content/snippets/adbanner/homepage/?location=Configuration Banner")
      .then((response) => {
        for (var i = 0; i < response.data.items.length; i++) {
          if (response.data.items[i].name == category.value + " Configuration") {
            banner.value = response.data.items[i].image.meta.download_url
          }
        }
      })
      .catch((err) => {
        error.value = err;
      });

    //get items
    getAPI
      .get("/product/system")
      .then((response) => {
        result.value = response.data.results;
        for(let i in result.value){
           
          if(result.value[i].parent_category==cate_in_db){
            let image = ""
            try {image = result.value[i].image.split(",")[0]}
            catch {image = ""}

            item.value.push({
              "id": result.value[i].id,
              "itmno": result.value[i].itmno,
              "title": result.value[i].title,
              "spec": result.value[i].spec.split(/\r\n\r\n|\r\r|\n\n/)[0],
              "parent_catid": result.value[i].parent_catid,
              "parent_category": result.value[i].parent_category,
              "catid": result.value[i].catid,
              "category":result.value[i].category,
              "image": image
            })
          }
        }
      })
      .catch((err) => {
        error.value = err;
      });

    return { error, item, breadcrumb_links, banner, category };
  },
};
</script>

<style scoped>
.submenu-content {
  padding: 0 30px 30px;
}
.card-footer {
    background-color:white
} 
.buy_button {background-color: #e56600; border-color: #e56600; width:50%;}
.buy_button:hover {background-color: #cc5c02; border-color: #cc5c02;width:50%;}
#banner {
  clear: both;
  padding: 100px 40px 140px;
  background: url("/img/Group_32.7f518062.png") no-repeat;
  background-size: cover;
}
</style>