<template>
  <div id="submenu">
    <router-link :to="{ name: 'Config', params: {category: 'server'} }" class="list-group-item" :class="{active: menu_list.Server}">Server Configuration<i class="fa fa-chevron-right"></i></router-link>
    <router-link :to="{ name: 'Config', params: {category: 'desktop'} }" class="list-group-item" :class="{active: menu_list.Desktop}">Desktop Configuration <i class="fa fa-chevron-right"></i></router-link>
    
    
  </div>
</template>


<script>
import { ref } from 'vue'

export default {
  props: [ 'menu' ],
  setup(props) {

    const menu_list = ref({
        Server: false,
        Desktop: false,
      })
    
    menu_list.value[props.menu] = true
     
    return { menu_list }
  }
}
</script>